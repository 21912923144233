<template>
  <section
    v-if="systemSettings"
    class="invoice-add-wrapper"
  >
    <validation-observer ref="rules">

      <b-form
        ref="mainForm"
        @submit.prevent
      >
        <b-row class="invoice-add">

          <!-- Col: Left (Invoice Container) -->

          <b-col
            cols="12"
            xl="9"
            md="8"
          >
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">
                <div class="d-flex justify-content-between flex-md-row flex-column mt-0">

                  <!-- Header: Left Content -->
                  <div>
                    <div class="logo-wrapper">
                      <b-img
                        :src="require('@/assets/images/logo/tm-partial-logo-2.png')"
                        alt="logo"
                      />
                      <h3 class="text-primary invoice-logo ml-0">
                        {{ appName }}
                      </h3>
                    </div>
                    <b-card-text class="mb-25">
                      {{ systemSettings.company_name }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ systemSettings.company_address1 }}
                    </b-card-text>
                    <b-card-text class="mb-0">
                      {{ systemSettings.company_address2 }}
                    </b-card-text>
                    <b-card-text class="mb-0">
                      Tax-ID/VAT: {{ systemSettings.vatno }}
                    </b-card-text>
                  </div>

                  <!-- Header: Right Content -->
                  <div class="invoice-number-date mt-md-0 mt-2">
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                      <h4 class="invoice-title">
                        Invoice
                      </h4>
                      <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="invoice-data-id"
                          v-model="invoiceNo"
                          disabled
                        />
                      </b-input-group>
                    </div>
                    <div class="d-flex align-items-center mb-1">
                      <span class="title">
                        Date Issued:
                      </span>
                      <flat-pickr
                        v-model="formData.issueDate"
                        class="form-control invoice-edit-input"
                      />
                    </div>
                    <div class="d-flex align-items-center">
                      <span class="title">
                        Due Date:
                      </span>
                      <flat-pickr
                        v-model="formData.dueDate"
                        class="form-control invoice-edit-input"
                      />
                    </div>
                  </div>
                </div>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Client & Payment Details -->
              <b-card-body
                class="invoice-padding pt-0"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Invoice To:"
                      label-for="invoice-to"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="invoice-to"
                        rules="required"
                      >
                        <v-select
                          id="invoice-to"
                          v-model="formData.invoiceTo"
                          :options="invoiceTo"
                          @input="clientSelected"
                        >
                          <template #search="{attributes, events}">
                            <input
                              class="vs__search"
                              :required="!formData.invoiceTo"
                              v-bind="attributes"
                              v-on="events"
                            >
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Client"
                      label-for="client"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="client"
                        rules="required"
                      >
                        <b-form-input
                          id="client"
                          v-model="formData.client"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Country"
                      label-for="country"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="country"
                        rules="required"
                      >
                        <v-select
                          id="country"
                          v-model="formData.country"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="countries"
                          label="name"
                          @input="checkVat"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tax-ID/VAT"
                      label-for="tax"
                    >
                      <b-form-input
                        id="tax"
                        v-model="formData.tax"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="address"
                      rules="required"
                    >
                      <b-form-group
                        label="Address"
                        label-for="address"
                      >
                        <b-form-textarea
                          id="address"
                          v-model="formData.address"
                          rows="4"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Items Section -->
              <b-card-body class="invoice-padding form-item-section">
                <div
                  ref="form"
                  class="repeater-form"
                  :style="{height: trHeight}"
                >
                  <b-row
                    v-for="(item, index) in formData.invoiceData.items"
                    :key="index"
                    ref="row"
                    class="pb-2"
                  >

                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">

                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            Item
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            Qty
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            Unit Price
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            Item Price
                          </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>

                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            <label class="d-inline d-lg-none">Item</label>

                            <validation-provider
                              #default="{ errors }"
                              :name="'item_title' + index"
                              rules="required"
                            >
                              <v-select
                                v-if="item.type === 'package'"
                                :id="'item_title' + index"
                                v-model="item.itemTitle"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="packages"
                                label="text"
                                :clearable="false"
                                class="item-selector-title"
                                placeholder="Select Package"
                                @input="updateTotal(index)"
                              />
                              <b-form-input
                                v-else
                                :id="'item_title' + index"
                                v-model="item.itemTitle"
                                placeholder="Enter Custom Name"
                                @blur="updateTotal(index)"
                              />
                              <small
                                v-if="errors[0]"
                                class="text-danger"
                              >{{ 'This is a required field.' }}</small>
                            </validation-provider>
                          </b-col>

                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <label class="d-inline d-lg-none">Qty</label>
                            <!-- <b-form-spinbutton
                              id="sb-default"
                              v-model="item.qty"
                              placeholder="--"
                              @change="updateTotal(index)"
                            /> -->
                            <b-form-input
                              v-model="item.qty"
                              @blur="updateTotal(index);isNumber(item)"
                            />
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                            class="d-flex align-items-center"
                          >
                            <label class="d-inline d-lg-none">Unit Price</label>
                            <p
                              v-if="item.type === 'package'"
                              class="mb-0"
                            >
                              {{ formData.currency }} {{ parseFloat(item.unitPrice).toFixed(2) }}
                            </p>
                            <b-input-group
                              v-else
                              :prepend="formData.currency"
                              class="input-group-merge"
                            >
                              <b-form-input
                                v-model="item.unitPrice"
                                @blur="updateTotal(index);isNumber2(item)"
                              />
                            </b-input-group>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                            class="d-flex align-items-center"
                          >
                            <label class="d-inline d-lg-none">Item Price</label>
                            <p
                              v-if="item.type === 'package'"
                              class="mb-0"
                            >
                              {{ formData.currency }} {{ parseFloat(item.itemPrice).toFixed(2) }}
                            </p>
                            <p
                              v-else
                              class="mb-0"
                            >
                              {{ formData.currency }} {{ parseFloat(item.itemPrice).toFixed(2) }}
                            </p>
                          </b-col>
                        </b-row>
                        <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeItem(index)"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div v-if="periodBoolean">
                  <b-row>
                    <b-col>
                      <div class="d-flex align-items-center mb-3 mt-1">
                        <h5 class="mr-1">
                          Period:
                        </h5>
                        <div class="d-flex align-items-center">
                          <flat-pickr
                            v-model="formData.periodDate.startDate"
                            class="form-control invoice-edit-input"
                            :config="{ altInput: true,altFormat: 'F j, Y', dateFormat: 'Y-m-d',}"
                          />
                          <h2 class="mx-1">
                            -
                          </h2>
                          <flat-pickr
                            v-model="formData.periodDate.endDate"
                            class="form-control invoice-edit-input"
                            :config="{ altInput: true,altFormat: 'F j, Y', dateFormat: 'Y-m-d',}"
                          />
                        </div>
                        <div class="py-50 px-25 ml-1">
                          <feather-icon
                            size="20"
                            icon="XIcon"
                            class="text-danger cursor-pointer"
                            @click="removePeriod"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  class="mr-1"
                  :disabled="packageBoolean"
                  @click="addNewItemInItemForm('package')"
                >
                  Insert Package
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  class="mr-1"
                  :disabled="periodBoolean"
                  @click="insertPeriod"
                >
                  Insert Period
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  class="mr-1"
                  @click="addNewItemInItemForm('custom')"
                >
                  Add Custom
                </b-button>
              </b-card-body>

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-3">
                <b-row>

                  <!-- Col: Total -->
                  <b-col
                    cols="12"
                    class="mt-md-6 d-flex justify-content-end"
                    order="1"
                    order-md="2"
                  >
                    <div class="invoice-total-wrapper">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Subtotal:
                        </p>
                        <p class="invoice-total-amount">
                          {{ formData.currency }} {{ formData.subTotal.toFixed(2) }}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          VAT ({{ formData.vatPercentage }}%):
                        </p>
                        <p class="invoice-total-amount">
                          {{ formData.currency }} {{ formData.vat.toFixed(2) }}
                        </p>
                      </div>
                      <hr class="my-50">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Total To Pay:
                        </p>
                        <p class="invoice-total-amount">
                          {{ formData.currency }} {{ formData.totalToPay.toFixed(2) }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>

          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions mt-md-0 mt-2"
          >

            <!-- Action Buttons -->
            <b-card>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mb-75"
                block
                :disabled="sending"
                type="submit"
                @click="createInvoice"
              >
                <span v-if="sending">
                  <b-spinner
                    small
                    type="grow"
                  />
                  Saving...
                </span>
                <span v-else>Save</span>
              </b-button>

              <!-- <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                block
                @click="previewInvoice"
              >
                Preview
              </b-button> -->
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                block
                @click="cancelCreate"
              >
                Cancel
              </b-button>
            </b-card>

            <div class="mt-2">
              <b-form-group
                label="Currency"
                label-for="currency"
              >
                <v-select
                  id="currency"
                  v-model="formData.currency"
                  :options="currencies"
                  class="payment-selector"
                  label="currency_name"
                  :clearable="false"
                />
              </b-form-group>
            </div>
            <div class="mt-2">
              <b-form-group
                label="Bank Details"
                label-for="bank-details"
              >
                <v-select
                  id="bank-details"
                  v-model="formData.bankDetails"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="bankProfiles"
                  class="payment-selector"
                  label="profile_name"
                  :clearable="false"
                />
              </b-form-group>
            </div>

            <!-- Payment Terms -->
            <!-- <div class="d-flex justify-content-between align-items-center">
              <label>Existing Romanian Companies</label>
              <b-form-checkbox
                v-model="formData.existingClients"
                switch
                @change="changeCompany"
              />
            </div> -->
          </b-col>
        </b-row>
      </b-form>

    </validation-observer>

  </section>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { $themeConfig } from '@themeConfig'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  // BFormCheckbox,
  VBToggle,
  BImg,
  // BFormSpinbutton,
  BInputGroup, BInputGroupPrepend, BCardText, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.min'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    flatPickr,
    // BFormCheckbox,
    BImg,
    // BFormSpinbutton,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  props: {
    previewData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      sending: false,
      appName: null,
      currencies: ['€', '$', 'Lei'],
      nameState: null,
      systemSettings: null,
      invoiceTo: [],
      users: [],
      countries: [],
      packageList: [],
      packages: [],
      bankProfiles: [],
      periodBoolean: false,
      packageBoolean: false,
      invoiceNo: 0,
      invoiceNos: {
        v1: 0,
        v2: 0,
      },
      allSystemSettings: null,
      formData: {
        currency: '€',
        issueDate: '',
        dueDate: '',
        invoiceTo: null,
        client: '',
        address: '',
        tax: '',
        country: '',
        subTotal: 0,
        totalToPay: 0,
        vat: 0,
        vatPercentage: 0,
        bankDetails: '',
        existingClients: false,
        periodDate: {
          startDate: null,
          endDate: null,
        },
        invoiceData: {
          items: [],
          itemsFormatted: [],
        },
        invoiceDataFormatted: {
          items: [],
        },
        method: 'create',
        requiredFields: false,
        hasPackageItem: false,
        invoiceNos: {
          v1: 0,
          v2: 0,
        },
        packageList: [],
        isNew: true,
      },
    }
  },
  created() {
    this.appName = $themeConfig.app.appName

    window.addEventListener('resize', this.initTrHeight)
    this.newInvoiceDetails()

    let issueDate = new Date()
    issueDate.setMonth(issueDate.getMonth() + 1)
    issueDate = `${issueDate.getFullYear()}-${issueDate.getMonth() + 1}-${issueDate.getDate()}`

    let dueDate = new Date()
    dueDate.setMonth(dueDate.getMonth() + 1)
    dueDate.setDate(dueDate.getDate() + 10)
    dueDate = `${dueDate.getFullYear()}-${dueDate.getMonth() + 1}-${dueDate.getDate()}`

    this.formData.issueDate = issueDate
    this.formData.dueDate = dueDate

    if (this.previewData) {
      this.formData = this.previewData
      this.periodBoolean = !!this.formData.periodDate.startDate

      const tempItem = this.formData.invoiceData.items.find(obj => obj.type === 'package')
      if (tempItem) this.packageBoolean = true
    }
  },
  mounted() {
    this.formData.requiredFields = false
    this.formData.invoiceData.hasPackageItem = false
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    checkFormValidity() {
      const isValid = this.$refs.mainForm.checkValidity()
      this.nameState = isValid
      return isValid
    },
    createInvoice() {
      this.$refs.rules.validate().then(success => {
        if (success) {
          if (!this.checkFormValidity()) {
            return
          }

          this.sending = true

          const tempItem = this.formData.invoiceData.items.find(obj => obj.type === 'package')
          this.invoiceNo = this.formData.existingClients ? this.invoiceNos.v1 : `TMC${this.invoiceNos.v2.toString().padStart(4, '0')}`

          const customData = []
          this.formData.invoiceData.items.forEach(item => {
            if (item.type === 'custom') customData.push(item)
          })

          // eslint-disable-next-line no-unused-vars
          const payload = {
            currency: this.formData.currency,
            packageName: tempItem ? tempItem.itemTitle.text : null,
            package: tempItem ? this.packageList[tempItem.itemTitle.index].id : null,
            packageQty: tempItem ? tempItem.qty : null,
            packageUnitPrice: tempItem ? tempItem.unitPrice : null,
            packageItemPrice: tempItem ? tempItem.itemPrice : null,
            periodStart: this.formData.periodDate.startDate,
            periodEnd: this.formData.periodDate.endDate,
            subtotal: this.formData.subTotal,
            vatPercent: this.formData.vatPercentage,
            vatValue: this.formData.vat,
            total: this.formData.totalToPay,
            bankingProfile: this.formData.bankDetails ? this.formData.bankDetails.id : null,
            // version: this.formData.existingClients ? 1 : 2,
            version: 2, // invoice should be invoiced by Romania company - version=2
            invoice_number_v2: this.formData.existingClients ? 0 : this.formData.invoiceNo,
            issueDate: this.formData.issueDate,
            dueDate: this.formData.dueDate,
            to: this.formData.client,
            userAddress: this.formData.address,
            userCountry: this.formData.country.code,
            userVAT: this.formData.tax,
            totalText: null,
            totalValue: null,
            uid: this.formData.invoiceTo.value,
            invoiceData: customData,
          }

          if (!(payload.package !== null || payload.invoiceData.length !== 0)) {
            this.sending = false
            this.notify({
              text: 'A package or a custom item is required. Please add one.',
              variant: 'danger',
            })
            this.addNewItemInItemForm('package')
            return
          }

          this.$store.dispatch('invoice/storeNewInvoice', payload)
            .then(res => {
              if (res) {
                this.sending = false
                this.notify({
                  text: 'Invoice created successfully!',
                  variant: 'success',
                })
                this.$router.push(`/custom-invoices/${res.id}`)
              }
            })
        }
      })
    },
    changeCompany(checked) {
      if (checked) {
        ({ 0: this.systemSettings } = this.allSystemSettings)
        this.invoiceNo = this.invoiceNos.v1
      } else {
        ({ 1: this.systemSettings } = this.allSystemSettings)
        this.invoiceNo = `TMC${this.invoiceNos.v2.toString().padStart(4, '0')}`
      }
    },
    insertPeriod() {
      const today = new Date()
      this.formData.periodDate.startDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
      this.formData.periodDate.endDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`

      this.periodBoolean = true
    },
    removePeriod() {
      this.periodBoolean = false
      this.formData.periodDate.startDate = null
      this.formData.periodDate.endDate = null
      this.initTrHeight()
    },
    previewInvoice() {
      this.$refs.rules.validate().then(success => {
        if (!this.checkFormValidity()) { return }
        if (success) this.formData.requiredFields = false
        else this.formData.requiredFields = true

        this.formData.systemSettings = this.systemSettings
        this.formData.invoiceNo = this.formData.existingClients ? this.invoiceNos.v1 : this.invoiceNos.v2

        // reset before assigning a values
        const itemsFormattedLength = this.formData.invoiceData.itemsFormatted.length
        this.formData.invoiceData.itemsFormatted.splice(0, itemsFormattedLength)

        this.formData.invoiceData.items.forEach(item => {
          if (!(item.itemTitle === null || item.itemTitle === '')) {
            this.formData.invoiceData.hasPackageItem = true
            this.formData.invoiceData.itemsFormatted.push({
              itemTitle: item.itemTitle,
              qty: item.qty,
              unitPrice: `${this.formData.currency} ${parseFloat(item.unitPrice).toFixed(2)}`,
              itemPrice: `${this.formData.currency} ${parseFloat(item.itemPrice).toFixed(2)}`,
            })
          }
        })

        this.formData.invoiceNos = this.invoiceNos
        this.formData.packageList = this.packageList
        this.$router.push({ name: 'custom-invoice-preview', params: { formData: this.formData } })
      })
    },
    checkVat() {
      const payload = {
        country_code: this.formData.country.code,
        uid: this.formData.invoiceTo.value,
      }
      this.$store.dispatch('auth/vatInfo', payload)
        .then(res => {
          if (this.formData.country) this.formData.vatPercentage = res.vatable ? res.vat : 0
          else this.formData.vatPercentage = 0
          this.formData.vat = this.formData.subTotal * (this.formData.vatPercentage / 100)
          this.formData.totalToPay = this.formData.subTotal + this.formData.vat
        })
    },
    updateTotal(index) {
      const itemTemp = this.formData.invoiceData.items[index]
      this.formData.subTotal = 0
      if (index != null && itemTemp.itemTitle !== null && itemTemp.qty !== 0) {
        if (itemTemp.type === 'custom' && itemTemp.unitPrice !== 0) {
          this.formData.invoiceData.items[index].itemPrice = itemTemp.qty * itemTemp.unitPrice
        } else if (itemTemp.type === 'package') {
          this.formData.invoiceData.items[index].unitPrice = this.packages[itemTemp.itemTitle.index].price
          this.formData.invoiceData.items[index].itemPrice = itemTemp.qty * this.packages[itemTemp.itemTitle.index].price
        }
      }

      this.formData.invoiceData.items.forEach(item => {
        this.formData.subTotal += item.itemPrice
      })

      this.formData.vat = this.formData.subTotal * (this.formData.vatPercentage / 100)
      this.formData.totalToPay = this.formData.subTotal + this.formData.vat
    },
    clientSelected() {
      this.$store.dispatch('invoice/getClientInvoiceDetails', this.formData.invoiceTo.value)
        .then(res => {
          if (this.formData.invoiceTo.value.toString().substring(0, 1) !== 'e') {
            this.formData.client = res.user.bill_name
            this.formData.tax = res.user.bill_vatno

            let address = ''
            if (typeof (res.user.bill_address1) !== 'undefined') address = `${address} ${res.user.bill_address1}`
            if (typeof (res.user.city_comp) !== 'undefined') address = `${address} ${res.user.city_comp}`
            if (typeof (res.user.bill_postcode) !== 'undefined') address = `${address} ${res.user.bill_postcode}`
            if (typeof (res.user.region_comp) !== 'undefined') address = `${address} ${res.user.region_comp}`

            this.formData.address = address
            this.formData.country = this.countries.find(obj => obj.code === res.user.bill_country)
          } else {
            this.formData.client = res.user.user_name
            this.formData.tax = res.user.user_vat
            this.formData.address = res.user.user_address
            this.formData.country = this.countries.find(obj => obj.code === res.user.user_country)
          }

          this.checkVat()
        })
    },
    newInvoiceDetails() {
      this.$store.dispatch('invoice/fetchNewInvoiceDetails')
        .then(res => {
          this.countries = res.countries
          this.packageList = res.packages
          this.bankProfiles = res.bankingProfiles
          this.formData.bankDetails = this.bankProfiles.find(obj => obj.role === 1)
          this.invoiceNos.v2 = res.v2NewInvoiceNo
          this.invoiceNo = `TMC${this.invoiceNos.v2.toString().padStart(4, '0')}`
          this.allSystemSettings = res.systemSettings;
          [, this.systemSettings] = res.systemSettings

          this.invoiceTo = res.users
          const tempPackages = res.packages

          tempPackages.forEach((item, index) => {
            const payload = {
              text: `${item.name.substr(0, 1)}${item.name.substr(1).toLowerCase()} Package`,
              price: item.price1,
              index,
            }

            this.packages.push(payload)
          })
          this.initTrHeight()
        })
    },
    addNewItemInItemForm(type) {
      this.$refs.form.style.overflow = 'hidden'
      this.formData.invoiceData.items.push({
        itemTitle: null, qty: 1, unitPrice: 0, itemPrice: 0, type,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })

      if (type === 'package') this.packageBoolean = true
    },
    removeItem(index) {
      if (this.formData.invoiceData.items[index].type === 'package') this.packageBoolean = false

      this.formData.invoiceData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
      this.updateTotal()
      this.initTrHeight()
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    isNumber(inpt) {
      const quantity = Number(inpt.qty)
      if (Number.isNaN(quantity)) {
        inpt.qty = 0 // eslint-disable-line
        this.notify({
          text: 'The Quantity is not valid, and the system assign 0 as a default value. ',
          variant: 'danger',
        })
      }
      return true
    },
    isNumber2(inpt) {
      const unitPrice = Number(inpt.unitPrice)
      if (Number.isNaN(unitPrice)) {
        inpt.unitPrice = 0 // eslint-disable-line
        this.notify({
          text: 'The Unit Price is not valid, and the system assign 0 as a default value. ',
          variant: 'danger',
        })
      }
      return true
    },
    cancelCreate() {
      this.$router.push('/billing/invoices-register')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}

.vs__dropdown-toggle {
  border: 1px solid #d8d6de !important;
}

input.vs__search::placeholder {
  color: #adadad;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.invoice-details-logo {
  width: 100%;
  max-width: 25rem;
}

.form-item-section {
  background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
